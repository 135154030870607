import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ModelAudit } from 'src/app/shared/models/audit';
import { ModelTableHeader } from 'src/app/shared/models/table-header';
import { ModelTableHoverDetail, ModelTableHoverDetailItem, ModelTableHoverDetailItemWithIndex } from 'src/app/shared/models/models';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { MatMenuTrigger } from '@angular/material/menu';
import { ValuesDialogBoxComponent } from './values-component/values-dialog-box/values-dialog-box.component';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {
  public _mFormGroup: FormGroup;
  public mUserName = new FormControl("");
  public mFromDate = new FormControl("", Validators.required);
  public mToDate = new FormControl("", Validators.required);
  _mColumns: any;
  _data: any = {};
  _commondata: any[] = [];
  str: any = {};
  changedData: any;
  modelAudits: any[] = [];
  templateflag: any = false;
  oldValue: any;
  newValue: any;
  id: any;
  name: any;
  data1: any;
  list: any;
  allExpandState: any;
  _matColumns: ModelTableHeader[];
  searchSubject$ = new Subject<string>();
  _mCurrentUserData: any;
  userInfoSearchResult: any;
  hoverFlag: boolean = false;
  employeeName: any = '';
  tempData: any;
  rawData: any;
  value: any;
  minDate = new Date();

  constructor(
    public apiService: ApiService,
    private fb: FormBuilder,
    private notifyService: NotificationService,
    public readonly serviceLocalStorage: ServiceLocalStorage,
    private ngxLoader: NgxUiLoaderService,
    public readonly dialog: MatDialog,
    public dialogBox: MatDialog,
    public dialogRef: MatDialogRef<AuditLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: any,

  ) {
    if (data.header) {
      if (data.header == 'present') {
        this._mColumns = data.header;
        this.rawData = data.data;
        this.value = this.rawData.pop();
        this.rawData.forEach(element => {
          if (element.object2) {
            if (Object.keys(element.object2).length > 0) {
              this._commondata.push(element);
            }
          }
          else {
            this._commondata.push(element);
          }
        })
        this.tempData = this._commondata;
      } else {
        // this._matColumns = data.header;
        // this._data = this._processTableData(data.data);
        // this._data.view = data.view;
      }
    } else {
      this.value = data.data.pop();
      this._data = data.data;
    }
    this._mCurrentUserData = this.serviceLocalStorage.getUserObject();
    this.searchSubject$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(searchValue => {

      let spocId: any = '';
      if (this._mCurrentUserData.userInfoDetails.role.id === 4) {
        spocId = this._mCurrentUserData.id;
      } else {
        spocId = '';
      }
      if (searchValue.length >= 1 && searchValue !== '') {
        this.apiService.doGetRequestWithResponse(ApiConstants.searchUserByFirstName + '?searchkey=' +
          searchValue + '&spocId=' +
          spocId,
          success => {
            if (success.status == 200) {
              this.userInfoSearchResult = success.body;
            } else {
              this.userInfoSearchResult = [];
            }

          },
          error => {

          }
        );
      } else {
        // this.mEmployeeId.setValue('');
      }
    });
  }

  ngOnInit(): void {
    this._mFormGroup = this.fb.group({
      userName: this.mUserName,
      fromDate: this.mFromDate,
      toDate: this.mToDate,
    });
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  auditDetails(modifiedData, index) {
    this.ngxLoader.start();
    if (modifiedData.module) {
      if (modifiedData.revisionType == 'Trip Generated') {
        this.notifyService.showInfo("Trip generated", null);
      }
      else {
        let revisionIds = [];
        let prevRevId: any;
        let previdObj: any;
        this.rawData.forEach((element) => {
          if ((element.module == modifiedData.module)) {
            if ((modifiedData.module == 'TripU')) {
              if (modifiedData.tripUserId == element.tripUserId) {
                revisionIds.push(element);
              }
            }
            else {
              revisionIds.push(element);
            }
          }
        });
        let index = revisionIds.findIndex(element => element == modifiedData);
        if (index !== 0) {
          previdObj = revisionIds[index - 1];
          prevRevId = previdObj.revisionId;
          this.tripsViewAudit(modifiedData, prevRevId);
        }
        else {
          this.notifyService.showInfo("No audit log found", null);
        }
      }
    }
    else {
      this.open(modifiedData, index);
    }
    this.ngxLoader.stop();
  }

  open(modifiedData, index) {
    if (modifiedData.revisionType == 'Created') {
      this.apiService.settableCreatedObs(true);
    }
    if (modifiedData.object2) {
      this.templateflag = true;
      this.changedData = modifiedData.object2;
      this.modelAudits = [];
      Object.keys(this.changedData).forEach(key => {
        let newobject = new ModelAudit();
        newobject.fieldNames = key;
        this.name = null;
        this.id = null;
        if (typeof (this.changedData[key].right) !== 'object' || typeof (this.changedData[key].left) !== 'object') {
          newobject.oldValues = this.changedData[key].left;
          newobject.newValues = this.changedData[key].right;
        }
        else if (typeof (this.changedData[key].right) === 'object' || typeof (this.changedData[key].left) === 'object') {
          if (this.changedData[key].left != null) {
            newobject.oldValues = this.getOldValues(this.changedData[key].left);
          }
          newobject.newValues = this.getNewValues(this.changedData[key].right);
        }
        if (newobject.newValues !== newobject.oldValues) {
          this.modelAudits.push(newobject);
        }
      })
      this.modelAudits.push({ "updatedDate": modifiedData.updatedDate })
      this.openDialog(this.modelAudits);
    }
    else {
      this.ngxLoader.start();
      let prevRevId;
      if (modifiedData.revisionType == 'Created') {
        prevRevId = 0;
        this.apiService.settableAuditViewObs(true);
      }
      else {
        prevRevId = this._commondata[index + 1].revisionId;
      }
      let value;
      this.apiService.getsubscriptionAuditObs().subscribe((res: any) => {
        if (res == true) {
          value = true;
        } else {
          value = false;
        }
      });
      if (value) {
        this.apiService.doGetRequestWithResponse(ApiConstants.subscriptionSetupOnClick + prevRevId + "&currentRevId=" + modifiedData.revisionId, (success) => {
          this.ngxLoader.stop();
          if (success.status == 200) {
            this.templateflag = true;
            this.changedData = success.body.data.object2;
            this.modelAudits = [];
            if (this.changedData) {
              Object.keys(this.changedData).forEach(key => {
                let newobject = new ModelAudit();
                newobject.fieldNames = key;
                this.name = null;
                this.id = null;
                if (typeof (this.changedData[key].right) !== 'object' || typeof (this.changedData[key].left) !== 'object') {
                  newobject.oldValues = this.changedData[key].left;
                  newobject.newValues = this.changedData[key].right;
                }
                else if (typeof (this.changedData[key].right) === 'object' || typeof (this.changedData[key].left) === 'object') {
                  if (this.changedData[key].left != null) {
                    newobject.oldValues = this.getOldValues(this.changedData[key].left);
                  }
                  newobject.newValues = this.getNewValues(this.changedData[key].right);
                }
                if (newobject.newValues !== newobject.oldValues) {
                  this.modelAudits.push(newobject);
                }
              });
            }
            else {
              this.modelAudits.push(success.body.data);
            }
            this.modelAudits.push({ "updatedDate": modifiedData.updatedDate })
            this.openDialog(this.modelAudits);
          } else {
            this.notifyService.showWarning("No audit logs found", null);
          }
        }, (error) => {
          this.ngxLoader.stop();
          this.notifyService.showWarning("No audit logs found.", null);
        });
      }
      else if (this.value[0] == 'displayName') {
        this.apiService.doGetRequestWithResponse(ApiConstants.userAuditForV3OnClick + prevRevId + "&currentRevId=" + modifiedData.revisionId, (success) => {
          this.ngxLoader.stop();
          if (success.status == 200) {
            this.templateflag = true;
            this.changedData = success.body.data.object2;
            this.modelAudits = [];
            if (this.changedData) {
              Object.keys(this.changedData).forEach(key => {
                let newobject = new ModelAudit();
                newobject.fieldNames = key;
                this.name = null;
                this.id = null;
                if (typeof (this.changedData[key].right) !== 'object' || typeof (this.changedData[key].left) !== 'object') {
                  newobject.oldValues = this.changedData[key].left;
                  newobject.newValues = this.changedData[key].right;
                }
                else if (typeof (this.changedData[key].right) === 'object' || typeof (this.changedData[key].left) === 'object') {
                  if (this.changedData[key].left != null) {
                    newobject.oldValues = this.getOldValues(this.changedData[key].left);
                  }
                  newobject.newValues = this.getNewValues(this.changedData[key].right);
                }
                if (newobject.newValues !== newobject.oldValues) {
                  this.modelAudits.push(newobject);
                }
              });
            }
            else {
              this.modelAudits.push(success.body.data);
            }
            this.modelAudits.push({ "updatedDate": modifiedData.updatedDate })
            this.openDialog(this.modelAudits);

          } else {
            this.notifyService.showWarning("No audit logs found", null);
          }
        }, (error) => {
          this.ngxLoader.stop();
          this.notifyService.showWarning("No audit logs found.", null);
        });
      }
      else {
        this.apiService.doGetRequestWithResponse(ApiConstants.routingSetupAuditDetails + prevRevId + "&currentRevId=" + modifiedData.revisionId, (success) => {
          this.ngxLoader.stop();
          if (success.status == 200) {
            this.templateflag = true;
            this.changedData = success.body.data.object2;
            this.modelAudits = [];
            if (this.changedData) {
              Object.keys(this.changedData).forEach(key => {
                let newobject = new ModelAudit();
                newobject.fieldNames = key;
                this.name = null;
                this.id = null;
                if (typeof (this.changedData[key].right) !== 'object' || typeof (this.changedData[key].left) !== 'object') {
                  newobject.oldValues = this.changedData[key].left;
                  newobject.newValues = this.changedData[key].right;
                }
                else if (typeof (this.changedData[key].right) === 'object' || typeof (this.changedData[key].left) === 'object') {
                  if (this.changedData[key].left != null) {
                    newobject.oldValues = this.getOldValues(this.changedData[key].left);
                  }
                  newobject.newValues = this.getNewValues(this.changedData[key].right);
                }
                if (newobject.newValues !== newobject.oldValues) {
                  this.modelAudits.push(newobject);
                }
              });
            }
            else {
              this.modelAudits.push(success.body.data);
            }
            this.modelAudits.push({ "updatedDate": modifiedData.updatedDate })
            this.openDialog(this.modelAudits);

          } else {
            this.notifyService.showWarning("No audit logs found", null);
          }
        }, (error) => {
          this.ngxLoader.stop();
          this.notifyService.showWarning("No audit logs found.", null);
        });
      }
    }
  }

  getOldValues(data) {
    this.str = JSON.stringify(data);
    this.data1 = JSON.parse(this.str);
    this.list = " ";
    if (Array.isArray(this.data1)) {
      this.data1.forEach(element => {
        if (typeof (element) !== 'object') {
          this.list = this.data1.toString();
        } else {
          Object.keys(element).forEach(s => {
            this.data1 = element
            if (s.toString().toLowerCase().endsWith('type')) {
              this.list = this.list + " " + element[s]
            }
            if (s.toString().toLowerCase().endsWith('name') && !s.toString().toLowerCase().endsWith('rname'))
              this.list = this.list + " " + element[s]
          });
        }
      })
      return this.list;
    } else {
      if (this.data1 !== null) {
        this.oldValue = "";
        Object.keys(this.data1).forEach(s => {
          if (!s.toString().toLowerCase().endsWith('personname') && (s.toString().toLowerCase().endsWith('name'))) {
            this.name = this.data1[s]
          }
          if (s.toString().toLowerCase().endsWith('id')) {
            this.id = this.data1[s];
          }
          if (!this.name && !this.id) {
            this.oldValue = null
          } else if (this.name && this.id) {
            this.oldValue = "id: " + this.id + " name: " + this.name;
          } else if (this.id) {
            this.oldValue = "id: " + this.id;
          }
        })
      }
      return this.oldValue;
    }
  }

  getNewValues(data) {
    this.str = JSON.stringify(data);
    this.data1 = JSON.parse(this.str);
    this.list = " ";
    if (Array.isArray(this.data1)) {
      this.data1.forEach(element => {
        if (typeof (element) != 'object') {
          this.list = this.data1.toString();
        } else {
          Object.keys(element).forEach(s => {
            this.data1 = element
            if (s.toString().toLowerCase().endsWith('type')) {
              this.list = this.list + " " + element[s]
            }
            if (s.toString().toLowerCase().endsWith('name') && !s.toString().toLowerCase().endsWith('rname'))
              this.list = this.list + " " + element[s]
          });
        }
      })
      return this.list;
    } else {
      if (this.data1 !== null) {
        this.newValue = "";
        Object.keys(this.data1).forEach(s => {
          if (!s.toString().toLowerCase().endsWith('personname') && (s.toString().toLowerCase().endsWith('name'))) {
            this.name = this.data1[s]
          }
          if (s.toString().toLowerCase().endsWith('id')) {
            this.id = this.data1[s];
          }
          if (!this.name && !this.id) {
            this.newValue = null
          } else if (this.name && this.id) {
            this.newValue = "id: " + this.id + " name: " + this.name;
          } else if (this.id) {
            this.newValue = "id: " + this.id;
          }
        })
      }
      return this.newValue;
    }
  }

  openDialog(data) {
    const dialogRef = this.dialogBox.open(ValuesDialogBoxComponent, {
      width: 'auto',
      disableClose: true,
      data: data,
    });
  }

  searchSubmit(userSelected) {
    if (userSelected) {
      this.employeeName = userSelected.firstName;
      this.filterResults();
    }
  }

  filterResults() {
    let results = this.tempData;
    let startDate: any;
    let endDate: any;
    if (this.mFromDate.value) {
      startDate = this.mFromDate.value;
    }
    if (this.mToDate.value) {
      endDate = this.mToDate.value;
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds("00");
    }
    this._commondata = results.filter(ele => {
      var date = new Date(ele['updatedDate']);
      if (((startDate == "") || (startDate == undefined)) && ((endDate == "") || (endDate == undefined)) && (this.employeeName)) {
        return (ele['updatedBy'] == this.employeeName);
      }
      else if ((this.employeeName == '') || ((this.employeeName == undefined)) && ((startDate !== "") || (startDate !== undefined)) && ((endDate !== "") || (endDate !== undefined))) {
        return ((date >= startDate) && (date <= endDate));
      }
      else {
        return ((date >= startDate) && (date <= endDate) && (ele['updatedBy'] == this.employeeName));
      }
    });
  }

  dateChanged(date) {
    this.mToDate.setValue("");
    this.minDate = date.value;
    if (this.mFromDate.value) {
      if (this.mToDate.value) {
        this.filterResults();
      }
      else {
        this.notifyService.showInfo("Please enter to Date", null);
      }
    }
    else {
      this.notifyService.showInfo("Please enter from Date", null);
    }
  }

  ngOnDestroy() {
    this.apiService.setsubscriptionAuditObs(false);
  }

  formatDate(d): any {
    let m = d.split('-');
    return m[2] + "-" + m[1] + '-' + m[0];
  }

  tripsViewAudit(modifiedData, prevRevId) {
    this.apiService.doGetRequestWithResponse(ApiConstants.tripAuditDetailed + prevRevId + "&currentRevId=" + modifiedData.revisionId +
      '&module=' + modifiedData.module + '&tripUserId=' + modifiedData.tripUserId, (success) => {
        this.ngxLoader.stop();
        if (success.status == 200) {
          this.templateflag = true;
          this.changedData = success.body.data.object2;
          this.modelAudits = [];
          if (this.changedData) {
            Object.keys(this.changedData).forEach(key => {
              let newobject = new ModelAudit();
              newobject.fieldNames = key;
              this.name = null;
              this.id = null;
              if (typeof (this.changedData[key].right) !== 'object' || typeof (this.changedData[key].left) !== 'object') {
                newobject.oldValues = this.changedData[key].left;
                newobject.newValues = this.changedData[key].right;
              }
              else if (typeof (this.changedData[key].right) === 'object' || typeof (this.changedData[key].left) === 'object') {
                if (this.changedData[key].left != null) {
                  newobject.oldValues = this.getOldValues(this.changedData[key].left);
                }
                newobject.newValues = this.getNewValues(this.changedData[key].right);
              }
              if (newobject.newValues !== newobject.oldValues) {
                this.modelAudits.push(newobject);
              }
            });
          }
          else {
            this.modelAudits.push(success.body.data);
          }
          this.modelAudits.push({ "updatedDate": modifiedData.updatedDate })
          this.openDialog(this.modelAudits);
        } else {
          this.notifyService.showWarning("No audit logs found", null);
        }
      }, (error) => {
        this.ngxLoader.stop();
        this.notifyService.showWarning("No audit logs found.", null);
      });
  }

}
